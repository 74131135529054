<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import { user_professions } from "@/config/api/user_professions";
import SubProfessionModal from "@/components/sub-user-professions/sub-user-professions-modal";
export default {


    components: {
        Layout,
        PageHeader,
        SubProfessionModal
    },
    data() {
        return {
            title: "Sub Profession Info",
            SubProfessionInfo: [],
            loading: false,
            tabIndex: 0,
            filter: null,
            filterOn: [],
            sortBy: "name",
            sortDesc: false,
            fields: [
                { key: "name", sortable: false, label: "Name Enlgish", thStyle: { width: "30%" } },
                { key: "name_ar", sortable: false, label: "Name Arabic", thStyle: { width: "30%" } },
                 { key: "actions", sortable: false },
            ],
            items: [
                {
                    text: "USER PROFESSIONS",
                    href: 'user-professions'
                },
                {
                    text: "SubProfession Info",
                    active: true,
                },
            ],
            currentProfession: null,
            selectedSubProfession: null
        };
    },
    async created() {
        console.log(this.$route.query.id)

        if (this.$route.query.id) {
            await this.loadInfo();
            this.loading = false;
        }
    },
    computed: {

    },
    methods: {

        showActionModal() {
            this.$bvModal.show("action-SubProfession");
        },
        async loadInfo() {

            if (this.$route.query.id) {
                const api = user_professions.sub_profession.get;
                api.params = { main_profession: this.$route.query.id };
                this.loading = true;
                try {
                    var res = await this.generateAPI(api);
                    this.SubProfessionInfo = res.data.professions;

                } catch (error) {
                    console.error(error.response.data);
                    this.$bvToast.toast("Could not get sub profession details!", {
                        title: "Failed",
                        variant: "danger",
                        solid: true,
                    });
                    let currentUser = JSON.parse(localStorage.getItem("user"));
                    console.log(currentUser);

                    setTimeout(() => {
                        this.$router.push({
                            path:
                                "user-professions",
                        });
                    }, 3000);
                }
                this.loading = false;

            }
        },

        hideActionModal() {
            this.$bvModal.hide("action-SubProfession");
            this.loadInfo();

        },
        addSubProfession(currentProfession, selectedSubProfession) {
            this.currentProfession = currentProfession;
            this.selectedSubProfession = selectedSubProfession;

            this.showActionModal();
        },
        deleteItem(id) {
            let api = { ...user_professions.sub_profession.delete };
            api.url += id;
            this.generateAPI(api)
                .then((res) => {
                    this.loadInfo();
                    console.log(res);
                    this.$bvToast.toast("SubProfession has been deleted!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
                })
                .catch((err) => {
                    this.$bvToast.toast("Could not delete SubProfession!", {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
                    console.log(err);
                });
        },


    },
    watch: {
        currentProfession(val) {
            this.values = val;
        },
        selectedSubProfession(val) {
            this.values = val;
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="loading">
            <div style="text-align: center" class="mt-5 mb-5">
                <pulse-loader color="#505d69" :loading="loading"></pulse-loader>
            </div>
        </div>
        <div class="row" v-else>
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="">

                            <div class="product-desc">

                                <div style="text-align: end; float-right" class="mb-2 mb-2">

                                    <b-button @click="addSubProfession($route.query.item)" pill variant="primary">
                                        Add +
                                    </b-button>
                                </div>

                                <div>
                                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                        <label class="d-inline-flex align-items-center">
                                            Search:
                                            <b-form-input v-model="filter" type="search"
                                                class="form-control form-control-sm ml-2">
                                            </b-form-input>
                                        </label>
                                    </div>
                                </div>

                                <div class="table-responsive">
                                    <b-table class="table-centered" :items="SubProfessionInfo" :fields="fields"
                                        responsive="sm" :busy="$store.state.api.loading" :sort-by.sync="sortBy"
                                        :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                        show-empty>
                                        <template #table-busy>
                                            <div style="text-align: center" class="mt-5 mb-5">
                                                <pulse-loader color="#505d69" :loading="true"></pulse-loader>
                                            </div>
                                        </template>

                                        <template v-slot:cell(name)="row">
                                            {{ row.value }}
                                        </template>
                                        <template v-slot:cell(name_ar)="row">
                                            {{ row.item.name_ar|| "-" }}
                                        </template>

                                        <template #cell(active)="row">
                                            {{ row.item.deletedAt ? "Deleted" : "Active" }}
                                        </template>
                                        <template #cell(actions)="row">
                                            <button class="action-btn" @click="deleteItem(row.item._id)">
                                                <i class="ri-delete-bin-line"></i>
                                            </button>
                                            <button class="action-btn" @click="
                                              addSubProfession($route.query.item, row.item)
                                            ">
                                                <i class="ri-edit-line"></i>
                                            </button>
                                        </template>
                                    </b-table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <SubProfessionModal :currentProfession="currentProfession" :selectedSubProfession="selectedSubProfession"
            v-on:closeModal="hideActionModal" />
    </Layout>
</template>

