<script>
import { user_professions } from "@/config/api/user_professions";

export default {
    props: ["currentProfession", "selectedSubProfession"],
    data() {
        return {

            loading: false,
            values: null,
            schema: [
                {
                    index: 1,
                    type: "text",
                    name: "name",
                    label: "SubProfession Name English",
                    placeholder: "John Doe",
                    "validation-messages": { required: "SubProfession Name is required" },
                    validation: "required",

                },
                {
                    index: 1,
                    type: "text",
                    name: "name_ar",
                    label: "SubProfession Name Arabic",
                    placeholder: "John Doe",
                    "validation-messages": { required: "SubProfession Name is required" },
                    validation: "required",

                },

            ],
        };
    },
    created() {
        if (!this.currentProfession) {
            this.currentProfession = this.selectedSubProfession.main_profession
        }
    },
    methods: {
        async submitHandler(data) {

            this.addSubProfession(data);

        },
        addSubProfession(data) {
            this.loading = true;
            let api = undefined;
            let updateData = data;

            if (this.selectedSubProfession) {
                api = { ...user_professions.sub_profession.update };
                updateData = {
                    name: data.name,
                    name_ar: data.name_ar,
                    id: this.selectedSubProfession._id,
                };
                api.url += this.selectedSubProfession._id;
            } else {
                api = { ...user_professions.sub_profession.create };
                updateData = {
                    name: data.name,name_ar: data.name_ar,
                    main_profession: this.currentProfession._id,
                };
            }
            api.data = { ...updateData };
            this.generateAPI(api)
                .then(() => {
                    this.$bvToast.toast("SubProfession added successfully!", {
                        title: "Create",
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("reloadData");
                    this.$emit("closeModal");
                })
                .catch((err) => {
                    this.$bvToast.toast("SubProfession is not added!", {
                        title: "Create",
                        variant: "danger",
                        solid: true,
                    });
                    console.error(err);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

    },
    watch: {
        selectedSubProfession(val) {
            this.values = val;

        },
    },
};
</script>

<template>
    <b-modal id="action-SubProfession" size="xl" @hidden="$emit('resetModal')" :title="'Add SubProfession'" hide-footer>
        <h6 style="margin-bottom:10px; font-size: 14px;">
            User Professions
        </h6>
        <div class="certificate-card">
            {{currentProfession.name}}
        </div>
        <FormulateForm name="SubProfessionAction" v-model="values" :schema="schema" @submit="submitHandler">
            <FormulateInput type="submit" class="float-right">
                <span v-if="loading">
                    <b-spinner class="vueformulate-loader"></b-spinner>

                </span>

            </FormulateInput>
        </FormulateForm>
    </b-modal>
</template>


<style>
.certificate-card {
    background-color: #f1f5f7;
    /* color: #000; */
    padding: 8px;
    border-radius: 8px;
    margin-bottom: 15px;
    font-size: 13px;
}

.payment-duration .formulate-input-group {
    display: flex;
}

.payment-duration .formulate-input-group-item {
    margin: 8px;
}

.payment-duration .formulate-input-label {
    margin-top: -6px;
}

@media (min-width: 1200px) {
    .modal-dialog {
        max-width: 1650px !important;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 1200px !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 950px !important;
    }
}
</style>
